import React, { useState, useCallback, useEffect } from 'react'
import styled from "styled-components";
import { 
    FcExpand, 
    FcCollapse, 
} from "react-icons/fc";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../context/auth-context';
import { fetchData, updateData } from '../utils/fetchers';

function TableRow({ row, flexRender, setResponse }) {
    const [rowIsOpen, setRowIsOpen] = useState(false);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [companyProducts, setCompanyProducts] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(row?.getVisibleCells() && row?.getVisibleCells().find(cell => cell.column.id === "status") ? row.getVisibleCells().find(cell => cell.column.id === "status").renderValue().toLowerCase() : "");

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    console.log(selectedStatus, 'selectedStatus');

    const updateCompanyStatus = useCallback(async (status) => {
        if (!isAuthed)
            return navigate("/login");

        const res = await updateData(`/api/dsp/companies/${row.original.id}/status`, { status })

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setResponse(res);
            setTimeout(() => {
                setResponse(null);
            }, 3500)
        }
    }, [navigate, setIsAuthed, isAuthed, row.original.id, setResponse]);

    const getCompanyProducts = useCallback(async () => {
        if (!isAuthed)
            return navigate("/login");

        const res = await fetchData(`/api/dsp/companies/products/${row.original.id}`);

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            const products = res.data.reduce((acc, curr, i, array) => {
                if (!acc.some(x => x.occupationId === curr.occupationId)) {
                    const filteredArray = array.filter(y => y.occupationId === curr.occupationId);
                    return [
                        ...acc,
                        {
                            occupationId: curr.occupationId,
                            occupationCode: curr.code,
                            occupationName: curr.name,
                            products: filteredArray.reduce((a, c) => {
                                if (!a.some(y => y.productId === c.productId)) {
                                    return [
                                        ...a,
                                        c.productId
                                    ]
                                }
                                return a;
                            }, [])
                        }
                    ]
                }
                return acc;
            }, []);

            setCompanyProducts(products);
        }
    }, [isAuthed, navigate, setIsAuthed, row.original.id]);

    const getCompanyById = useCallback(async (id) => {
        if (!isAuthed)
            return navigate("/login");

        const res = await fetchData(`/api/dsp/companies/${id}?includeAllData=true`);

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setCompanyInfo(res.data);
        }    
    }, [navigate, setIsAuthed, isAuthed])

    const handleOpenRow = () => {
        setRowIsOpen(prev => !prev);
    }

    const handleStatus = async (e) => {
        setSelectedStatus(e.target.value);
        await updateCompanyStatus(e.target.value);
    }

    useEffect(() => {
        if (rowIsOpen)
            getCompanyById(row.original.id);
    }, [rowIsOpen, getCompanyById, row.original.id])

    return (
        <>
            <tr>
                {row.getVisibleCells().map(cell => {
                    if (cell.column.id === "status") {
                        return (
                            <td key={cell.id}>
                                <Select name="status" id="statusSelect" onChange={handleStatus} value={selectedStatus}>
                                    <option value="interested">Påbörjad förfrågan</option>
                                    <option value="cancelled">Avbruten förfrågan</option>
                                    <option value="lead">Prospekt</option>
                                    <option value="customer">Kund</option>
                                    <option value="inactive">Inaktiverad</option>
                                </Select>
                            </td>
                        )
                    } else if (cell.column.id === "toggle") {
                        return (
                            <td 
                                style={{ 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    alignItems: "center", 
                                    height: "100%",
                                    cursor: "pointer"
                                }} 
                                key={cell.id} 
                                onClick={handleOpenRow}
                            >
                                {rowIsOpen ? <FcCollapse /> : <FcExpand />}
                            </td>
                        )
                    } else {
                        return (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        )
                    }
                })}
            </tr>
            {rowIsOpen && companyInfo &&
                <InfoRow>
                    <td colSpan="6">
                        <RowInfoContainer>
                            <div>
                                <div>
                                    <h4>Kontaktperson</h4>
                                    <span>{row.original.contactFirstName} {row.original.contactLastName}</span>
                                    <span>{row.original.contactEmail}</span>
                                    <span>{row.original.contactPhone}</span>
                                </div>
                                <div>
                                    <h4>Rabatt & referens</h4>
                                    <span><strong>Rabatt antal anställda:</strong> {row?.original?.discountByNumberOfEmployees ? `${(parseFloat(row.original.discountByNumberOfEmployees) * 100).toFixed(2)}%` : ""}</span>
                                    <span><strong>Turrabatt:</strong> {row?.original?.luckyDiscount ? `${parseFloat(row.original.luckyDiscount) * 100}%` : ""}</span>
                                    <span><strong>Referensrabatt:</strong> {row?.original?.referrerDiscount ? `${(parseFloat(row.original.referrerDiscount) * 100).toFixed(2)}%` : ""}</span>
                                    <span><strong>Rekommenderad av:</strong> {row.original.referrer}</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                <h4>Önskemål</h4>
                                <div>
                                    <div>
                                        <h5>Sortiment</h5>
                                        <div>
                                            {companyInfo.requirements.assortment.map(x => 
                                                <div key={x.id}>
                                                    <span><strong>{x.name}</strong></span>
                                                    {x?.options && x?.options?.length > 0 ?
                                                        <div>
                                                            {x.options.map(y =>
                                                                <span key={y.id}>{y.name}</span>    
                                                            )}
                                                        </div>
                                                    : null}
                                                </div>    
                                            )}
                                            <section>
                                                {!companyProducts || companyProducts.length === 0 ? <button className='btn btn-default' onClick={getCompanyProducts}>Se produkter</button> : null}
                                                <div>
                                                    <h5>Produkter</h5>
                                                    {companyProducts && companyProducts.map(x => 
                                                        <div key={x.occupationId}>
                                                            <span><strong>{x.occupationName}</strong></span>
                                                            {x?.products && x?.products?.length > 0 ?
                                                                <ProductsList>
                                                                    {x.products.map(y =>
                                                                        <span key={`${x.occupationCode}-${y}`}>{y}</span>    
                                                                    )}
                                                                </ProductsList>
                                                            : null}
                                                        </div>
                                                    )}
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Inställningar</h5>
                                        <div>
                                            {companyInfo.requirements.settings.map(x => 
                                                <div key={x.id}>
                                                    <span><strong>{x.name}</strong></span>
                                                </div>    
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <h5>VAS</h5>
                                        <div>
                                            {companyInfo.requirements.vas.map(x => 
                                                <div key={x.id}>
                                                    {x.gsOption ?
                                                        <span><strong>VAS hanteras av Gear Shift.</strong></span> :
                                                        <>
                                                            <span><strong>{x.name}</strong></span>
                                                            <div>
                                                                {x.placements.map(y =>
                                                                    <span key={y.id}>{y.name}</span>    
                                                                )}
                                                            </div>
                                                        </>
                                                    }
                                                </div>    
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Utprovning</h5>
                                        <div>
                                            {companyInfo.requirements.tryOut[0].address.address === "" ?
                                                <div><span><strong>Utprovning behövs inte.</strong></span></div> :
                                                <div>
                                                    <span><strong>Adress</strong></span>
                                                    <div>
                                                        <span>{companyInfo.requirements.tryOut[0].address.address}, {companyInfo.requirements.tryOut[0].address.city}</span>
                                                        {companyInfo.requirements.tryOut[0].hasTryOutOptions ?
                                                            <span><i>(Plagg skickas för utprovning)</i></span> : null
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {companyInfo.requirements.tryOut[0].hasTryOutOptions ? companyInfo.requirements.tryOut[0].sizes.map(x => {
                                                const maleSizes = x.options.filter(o => o.gender === "male");
                                                const womenSizes = x.options.filter(o => o.gender === "female");

                                                return (
                                                    <div key={x.id}>
                                                        <span><strong>{x.name}</strong></span>
                                                        <RowInfoSizesContainer>
                                                            <div>
                                                                <span><strong>Herr</strong></span>
                                                                <div>
                                                                    {maleSizes.map(ms => 
                                                                        <span key={ms.id}>{ms.name}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span><strong>Dam</strong></span>
                                                                <div>
                                                                    {womenSizes.map(ws => 
                                                                        <span key={ws.id}>{ws.name}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </RowInfoSizesContainer>
                                                    </div>
                                                )
                                                }) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </RowInfoContainer>
                    </td>
                </InfoRow>
            }
        </>
    )
}

export default TableRow;

const Select = styled.select`
    width: 100%;
    background-color: transparent;
    border: none;
    color: #eee;
    cursor: pointer;
`

const InfoRow = styled.tr`
    background-color: rgba(0,0,0,0.1) !important;
`

const RowInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;

    > div {
        display: flex;

        &:first-child {
            span {
                display: flex;
                justify-content: space-between;
    
                strong {
                    margin-right: 10px;
                }
            }
        }

        h4 {
            font-size: 15px;
            margin-bottom: 5px;
        }

        > div {
            margin: 15px;
        }

        &:last-child > div > div {
            display: flex;
            flex-wrap: wrap;
            
            > div {
                margin-right: 25px;

                h5 {
                    margin: 0 0 8px 0;
                    font-size: 13px;
                }

                > div > div {
                    margin-bottom: 5px;

                    span {
                        font-size: 13px;
                    }

                    > div {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }

    section {
        button {
            margin: 10px 0;
            font-size: 12px;
            font-weight: bold;
            border: none;
            background-color: var(--color-contrast-primary);
            border-radius: 10px;
            padding:10px 20px;
            color: var(--color-text-primary);
            cursor: pointer;
        }

        > div {
            padding: 10px 0;
        }
    }
`

const RowInfoSizesContainer = styled.div`
    flex-direction: row !important;

    > div {
        padding: 5px;

        > div {
            display: flex;
            flex-direction: column;
        }
    }
`

const ProductsList = styled.div`
    display: flex;
    flex-direction: column;
`